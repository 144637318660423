import {createTheme } from '@mui/material/styles';

const MainTheme = createTheme({
    palette: {
        primary: {
            main: '#000',
            light: '#fff',
            dark: '#000',
            contrastText: '#fff',
        },
        secondary: {
            light: '#61f2ff',
            main: '#61f2ff',
            dark: '#002bba',
            contrastText: '#fff',
        },
    },
});

export default MainTheme;
