import React from 'react';
import {Box} from "@mui/material";

var imageName = require('../assets/images/homepage.jpg');


// boxShadow: '0px 5px 0 10px #352d2d, 0px 15px 0 15px #000000',
//                     backgroundColor: '#c0392b',
//                     borderRadius: '50%',
//                     top: '50%',
//                     left: '50%',
//                     width: '100px',
//                     height: '80px',
//                     transform: 'translateX(-50%)',
//                     '&::before': {
//                         content: '""',
//                         zIndex: '1',
//                         borderRadius: '50%',
//                         backgroundColor: '#e74c3c',
//                         position: 'absolute',
//                         bottom: '40%',
//                         left: '0%',
//                         transition: 'bottom .2s',
//                         width: '100px',
//                         height: '80px',
//                     },
//                     '&::after': {
//                         content: '""',
//                         backgroundColor: '#c0392b',
//                         position: 'absolute',
//                         bottom: '50%',
//                         left: '0%',
//                         width: '100px',
//                         height: '30px',
//                         transition: 'height .2s',
//                     },
//                     '&:active': {
//                         '&::before': { bottom: '10%' },
//                         '&::after': { height: '10%' }
//                     },
//                     '&.MuiButton-root:hover': {
//                         backgroundColor: '#c0392b !important',
//                         color: 'unset !important',
//                     },
//                     '&.MuiButton-root.Mui-focusVisible': {
//                         backgroundColor: '#c0392b !important',
//                         color: 'unset !important',
//                     }

function Home() {

    return (
        <Box>
            <Box sx={{
                width: '40%',
                margin: '30px auto 0',
            }}>
                <Box sx={{
                    display: 'flex',
                    border: '0.2px solid grey',
                    padding: '30px',
                    borderColor: '#03a8c0',
                    borderRadius: '15px',
                }}>
                    <h2 style={{width: '100%', fontSize: '30px', alignSelf: 'center', textAlign: 'center'}}>WELCOME TO OUR APP!</h2>

                </Box>
            </Box>
            <div className="container" style={{width: '70%', margin: 'auto'}}>
                <img src={imageName} alt="homepage" style={{width: '100%'}}/> 
            </div>
        </Box>
    );
}

//<div style={{width: '24%', textAlign: 'center', paddingTop: '16px'}}>
//                        <Button variant="text" disableRipple href="/target_list"
//                            sx={{
//                                boxShadow: '0px 6px 0 10px #000000',
//                                backgroundColor: '#c0392b',
//                                borderRadius: '50%',
//                                marginBottom: '24px',
//                                width: '80px',
//                                height: '60px',
//                                '&::before': {
//                                    content: '""',
//                                    zIndex: '1',
//                                    borderRadius: '50%',
//                                    backgroundColor: '#e74c3c',
//                                    position: 'absolute',
//                                    bottom: '36%',
//                                    left: '0%',
//                                    transition: 'bottom .2s',
//                                    width: '80px',
//                                    height: '64px',
//                                },
//                                '&::after': {
//                                    content: '""',
//                                    backgroundColor: '#c0392b',
//                                    position: 'absolute',
//                                    bottom: '50%',
//                                    left: '0%',
//                                    width: '80px',
//                                    height: '20px',
//                                    transition: 'height .2s',
//                                },
//                                '&:active': {
//                                    '&::before': { bottom: '10%' },
//                                    '&::after': { height: '10%' }
//                                },
//                                '&.MuiButton-root:hover': {
//                                    backgroundColor: '#c0392b !important',
//                                    color: 'unset !important',
//                                }
//                        }}> </Button>
//                    </div>

export default Home;
