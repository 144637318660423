import React, {useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";

import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Box, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { useAuth } from '../utils/auth';

function Login() {
    const [email, setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [loading, setLoading] = React.useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const redirectPath = location.state?.path || '/';

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (e) => {
        setLoginError('');
        const {id , value} = e.target;
        if(id === "email"){
            setEmail(value);
        }
        if(id === "password"){
            setPassword(value);
        }
    }

    const handleSubmit  = () => {
        // TODO: validation here
        handleLogin();
    }

     const handleResend = () => {
            setLoginError('');
            setLoading(true);
            fetch(`${process.env.REACT_APP_API_ADDRESS}/resend_verify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
                // credentials odpopwiada za przechwytywanie Set-Cookie headerów z response i zapisanie ich w przeglądarce
                // credentials: 'include',
            })
            .then(response => {
                setLoading(false);
                let data;
                if (response.ok) {
                    data = response.json();
                } else {
                    data =  {message : "Error occurred! Check email or try again."}
                }
                return data;
            })
            .then(data => {
                setLoginError(data.message)
            })
        }

    const handleLogin = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_ADDRESS}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
            // credentials odpopwiada za przechwytywanie Set-Cookie headerów z response i zapisanie ich w przeglądarce
            // credentials: 'include',
        })
        .then(response => {
            setLoading(false);
            if (response.ok) {
                return response.json();
            } else {
                return Promise.reject(response.json());
            }
        })
        .then(data => {
            auth.login(data);
            navigate(redirectPath, {replace: true});
        })
        .catch(error => {
            error.then((data) => {
                // console.log(data)
                data.error && setLoginError(data.error)
            })
        });
    }

    return (
        <Box
            component="form"
            sx={{
                width: '40%',
                textAlign: 'center',
                margin: '30px auto',
                border: '0.2px solid grey',
                padding: '30px',
                borderRadius: '15px',
                boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="on"
            >
                <h1 className="text-uppercase text-center mb-5">Login to your account</h1>
                <Box sx={{marginTop: '50px', marginBottom: loginError ? '28px': '50px'}}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                        <EmailIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Your email"
                            size="small"
                            value={email}
                            autoComplete="username"
                            onChange = {(e) => handleInputChange(e)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                        <LockIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
                        <TextField
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            size="small"
                            value={password}
                            onChange = {(e) => handleInputChange(e)}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                    {loginError && (<p className="error"
                        style={{
                            color: loginError.includes('verification') ? '#03a8c0' : 'red',
                            textTransform: 'uppercase', marginBottom: 0, marginTop: 20}}>
                                {loginError}
                    </p>)}
                    {(loginError && loginError.includes('verify')) &&
                        (<p className="link" onClick={handleResend}
                            style={{cursor:'pointer', marginTop: '5px'}}>
                                Resend verification email.
                        </p>)
                    }
                </Box>

                <LoadingButton
                    size="medium"
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    sx={{marginBottom: '30px', width: '50%'}}
                    >
                    <span>LOG IN</span>
                </LoadingButton>

                <h2 style={{fontSize: '16px'}}>Don't have an account? <a href='/register' style={{textDecoration: 'underline'}}>Sign in here!</a></h2>
        </Box>
    );
}

export default Login;
