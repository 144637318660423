import React from "react";
import Home from "../Home";


function HomeRoute() {
//  const auth = useAuth();
//  if (!auth.user) {
    return <Home />;
//  }
//  return <Navigate to="/target_list" />;
};

export default HomeRoute;