import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Reaction from "./pages/Reaction";
import TargetList from "./pages/TargetList";
import NoPage from "./pages/NoPage";
import Layout from "./pages/components/Layout";
import Register from './pages/Register';
import Login from './pages/Login';
import User from './pages/User';
import Verify from './pages/Verify';
import MostLiked from './pages/MostLiked';
import ProtectedRoute from "./pages/components/ProtectedRoute";
import HomeRoute from './pages/components/HomeRoute';
import { AuthProvider } from './utils/auth';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomeRoute />} />
                        <Route path="home" element={<HomeRoute />} />
                        <Route path="login" element={<Login />} />
                        <Route path="user" element={
                            <ProtectedRoute> <User /> </ProtectedRoute>
                        } />
                        <Route path="most_liked" element={<MostLiked />} />
                        <Route path="register" element={<Register />} />}
                        <Route path="verify" element={<Verify />} />}
                        {/*<Route path="admin" element={<Redirect link={`${process.env.REACT_APP_API_ADDRESS}/admin`}/>} />*/}
                        <Route
                            exact
                            path="target_list"
                            element={
                                <TargetList />
                            }
                        />
                        <Route
                            path="target_list/:q"
                            element={
                                <TargetList />
                            }
                        /><Route
                            path="reaction/:id"
                            element={
                                <Reaction />
                            }
                        />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>

            </BrowserRouter>
        </AuthProvider>
    );
}

// <ProtectedRoute>
//     <Reaction />
// </ProtectedRoute>

export default App;

// function Redirect({link}) {
//   window.location.replace(link);
// }