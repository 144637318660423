import React, {useState, useEffect} from 'react';
import { useSearchParams } from "react-router-dom";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";

var imageName = require('../assets/images/homepage.jpg');

function Verify() {
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const [readyText, setReadyText] = useState("Please Wait...");
    // console.log(searchParams.get('email'))
    const navigate = useNavigate();

    const fetchActivate = (searchParams) => {

            fetch(`${process.env.REACT_APP_API_ADDRESS}/verify/${searchParams.get('token')}/${searchParams.get('email')}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                // credentials: 'include',
            })
                .then(response => {
                    let data;
                    if (!response.ok){
                        data = {status : "Verification link is expired or corrupted!"}
                    }
                    else {
                        data = response.json();
                    }
                    return data;
                })
                .then(data => {
                    data.status += "! You can Log In."
                    setReadyText(data.status)
                })

        }

    useEffect(() => {
     if(searchParams.get('email') && searchParams.get('token'))
        fetchActivate(searchParams);
     else
        navigate("/");
    }, []); // eslint-disable-line

    return (
        <Box>
            <Box sx={{
                width: '40%',
                margin: '30px auto 0',
            }}>
                <Box sx={{
                    display: 'flex',
                    border: '0.2px solid grey',
                    padding: '30px',
                    borderRadius: '15px',
                    borderColor: '#03a8c0'
                }}>
                    <h2 style={{width: '100%', fontSize: '30px', alignSelf: 'center', textAlign: 'center'}}>{readyText}</h2>

                </Box>
            </Box>
         <div className="container" style={{width: '70%', margin: 'auto'}}>
                        <img src={imageName} alt="homepage" style={{width: '100%'}}/>
                    </div>
        </Box>
    );
}

export default Verify;
