import React from 'react';
import {Outlet} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import MainTheme from '../../assets/themes/MainTheme'
import NavBar from "./NavBar";

function Layout() {
    return (
        <ThemeProvider theme={MainTheme}>
            <NavBar />
            <Outlet />
        </ThemeProvider>
    );
}

export default Layout;
