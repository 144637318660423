import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

//import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {Box, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

function Register() {
    const navigate = useNavigate();
//    const [firstName, setFirstName] = useState('');
//    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [registerErrorEmail, setRegisterErrorEmail] = useState('');
    const [registerErrorPassword, setRegisterErrorPassword] = useState('');
    const [loading, setLoading] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleInputChange = (e) => {


        const {id , value} = e.target;
//        if(id === "firstname"){
//            setFirstName(value);
//        }
//        if(id === "lastname"){
//            setLastName(value);
//        }
        if(id === "email"){
            setRegisterErrorEmail('');
            setEmail(value);
        }
        if(id === "password"){
            setRegisterErrorPassword('');
            setPassword(value);
        }
        if(id === "repeat-password"){
            setConfirmPassword(value);
        }
    }

    const handleSubmit  = () => {

        if(email.length < 5 || !email.includes("@")) {
            let arr = ["ENTER A VALID EMAIL ADDRESS."];
            setRegisterErrorEmail(arr);
        }
        else if (password.length < 8 && password === confirmPassword) {
            let arr = ["Password too short."];
            setRegisterErrorPassword(arr);
        }
        else if (password !== confirmPassword) {
            let arr = ["Passwords are different."];
            setRegisterErrorPassword(arr);
        }
        else
           signUp();

    }

    const signUp = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_ADDRESS}/register/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
		        password2: password
            }),
        })
        .then(response => {
            setLoading(false);
            let data
            if (response.ok) {
                navigate("/login");
                data = {email : "ok", password: "ok"}
                // console.log(response);
            } else {
                data = response.json();
                // console.log(response);
            }
            return data;
        })
        .then(data => {
//            data.email && setRegisterErrorEmail(`Email — ${data.email}`)
//            data.password && setRegisterErrorPassword(`Password — ${data.password}`)
            data.email && setRegisterErrorEmail(data.email)
            data.password && setRegisterErrorPassword(data.password)
        });
    }
        // <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
        //                    <PersonIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
        //                    <TextField
        //                        required
        //                        fullWidth
        //                        id="firstname"
        //                        label="Your first name"
        //                        size="small"
        //                        value={firstName}
        //                        InputLabelProps={{ shrink: true }}
        //                        onChange = {(e) => handleInputChange(e)}
        //                    />
        //                </Box>
        //                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
        //                    <PersonIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
        //                    <TextField
        //                        required
        //                        fullWidth
        //                        id="lastname"
        //                        label="Your last name"
        //                        size="small"
        //                        value={lastName}
        //                        InputLabelProps={{ shrink: true }}
        //                        onChange = {(e) => handleInputChange(e)}
        //                    />
        //                </Box>
    return (
        <Box
            component="form"
            sx={{
                width: '40%',
                textAlign: 'center',
                margin: '30px auto',
                padding: '0 30px 30px',
                border: '0.2px solid grey',
                borderRadius: '15px',
                boxShadow: '0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete="on"
            >
                <h1 className="text-uppercase text-center mb-5">Create an account</h1>

                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                    <EmailIcon sx={{ color: !registerErrorEmail ? 'action.active' :'red', mr: 0.2, my: 2.1 }} />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        required
                        fullWidth
                        id="email"
                        label="Your email"
                        size="small"
                        value={email}
                        autoComplete="email"
                        onChange = {(e) => handleInputChange(e)}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                    <LockIcon sx={{ color: !registerErrorPassword ? 'action.active' : 'red', mr: 0.2, my: 2.1 }} />
                    <TextField
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        size="small"
                        value={password}
                        autoComplete="off"
                        onChange = {(e) => handleInputChange(e)}
                        type={showPassword ? 'text' : 'password'}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-end', padding: '0 16px' }}>
                    <KeyIcon sx={{ color: 'action.active', mr: 0.2, my: 2.1 }} />
                    <TextField
                        required
                        fullWidth
                        id="repeat-password"
                        label="Repeat password"
                        size="small"
                        value={confirmPassword}
                        autoComplete="off"
                        onChange = {(e) => handleInputChange(e)}
                        type={showRepeatPassword ? 'text' : 'password'}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle repeat password visibility"
                                        onClick={handleClickShowRepeatPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Box>
                {registerErrorEmail && (<p className="error" style={{ textTransform: 'uppercase', marginBottom: 0, fontWeight:'bold'}}> EMAIL </p>)}
                {registerErrorEmail && (registerErrorEmail.map(function(data, id) {
                                              return (
                                                <p key={`ree${id}`} style={{width:'70%', margin:'0 auto',color: 'red', fontSize: '0.9em',textTransform: 'uppercase', textAlign:'center', marginBottom: 0}}>
                                                 {data}
                                                </p>
                                              )
                                          })
                                       )}
                {registerErrorPassword && (<p className="error" style={{textTransform: 'uppercase', marginBottom: 0, fontWeight:'bold' }}> PASSWORD </p>)}
                {registerErrorPassword && (registerErrorPassword.map(function(data, id) {
                                              return (
                                                <p key={`rep${id}`} style={{width:'70%', margin:'0 auto', color: 'red', fontSize: '0.9em', textTransform: 'uppercase', textAlign:'center', marginBottom: 0}}>
                                                 {data}
                                                </p>
                                              )
                                          })
                                       )}
                <LoadingButton
                    size="medium"
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                    sx={{margin: '24px 0'}}
                    >
                    <span>REGISTER</span>
                </LoadingButton>

                <h2 style={{fontSize: '16px'}}>Have already an account? <a href='/login' style={{textDecoration: 'underline'}}>Login here!</a></h2>
        </Box>
    );
}

export default Register;